import "./App.css";
import SideBar from "./SideBar";
import MapContainer from "./MapContainer";
import GoogleMap from "./GoogleMap";

import {
  BrowserRouter,
  Routes,
  Route,
  Link as RouterLink,
} from "react-router-dom";
import venues from "./data/Venues.json";

function PageHeader() {
  return (
    <div className="pageheader">
      <RouterLink to="/" className="nav_menuitem">
        Static Google Map
      </RouterLink>
      <RouterLink to="/apimap" className="nav_menuitem">
        Google Map React Component
      </RouterLink>
    </div>
  );
}

function App() {
  return (
    <>
      <BrowserRouter basename="/">
        <PageHeader />
        <SideBar venues={venues} />
        <Routes>
          <Route path="/" element={<MapContainer venue={venues[0]} />} />
          <Route path="/apimap" element={<GoogleMap />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
//37.98696067489039, -122.5880485746901
