import GoogleStaticMap from "./GoogleStaticMap";
import { useState } from "react";
// TODO Positioning issue
export default function MapContainer({ venue }) {
  const [venueName, setVenueName] = useState(venue.name);
  return (
    <div
      id="mapcontainer"
      style={{
        width: "100%",
        height: "100vh",
        zIndex: "200",
        position: "relative",
        overflow: "hidden",
        border: "solid red",
        paddingLeft: "300px",
        paddingTop: "50px",
        margin: "0",
      }}
    >
      <GoogleStaticMap venueName={venueName}></GoogleStaticMap>
    </div>
  );
}
