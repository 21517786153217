import "./Card.css";
function onClick(event) {
  console.log("changeColor clicked");
}
export default function Card({ venue }) {
  console.log("Inside Card");
  console.log(venue);
  return (
    <div className="card" onClick={onClick}>
      <h2>{venue.name}</h2>
      <p>{venue.description}</p>
      <ul>
        <li>Capacity: {venue.capacity}</li>
        <li>Square feet: {venue.square_footage}</li>
        <li>Amenities: {venue.amenities}</li>
        <li>Public transit: {venue.public_transit}</li>
        <li>
          <a href={venue.map_url} target="_new_win">
            Google Map
          </a>
        </li>
      </ul>
    </div>
  );
}
