import Card from "./Card";
import "./Sidebar.css";

export default function SideBar({ venues }) {
  const content = `
    Once upon a time there was a dear little girl who was loved by every
    one who looked at her, but most of all by her grandmother, and there
    was nothing that she would not have given to the child. 
  `;
  return (
    <>
      <div className="sidebar">
        {venues.map((venue) => (
          <Card venue={venue} />
        ))}
      </div>
    </>
  );
}

/* <Card heading="The Fillmore" content={content} />
<Card heading="Club 2" content={content} />
<Card heading="Club 3" content={content} />
<Card heading="Club 4" content={content} /> */
