export default function GoogleStaticMap() {
  const title = "The Fillmore Map";
  const googleUrl =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.2608639143064!2d-122.43794290960395!3d37.78392589974375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808580b9b206613b%3A0x44d95eb9bc33774!2sThe%20Fillmore!5e0!3m2!1sen!2sus!4v1712281772896!5m2!1sen!2sus";
  return (
    <>
      <iframe
        title={title}
        src={googleUrl}
        width="100%"
        height="100%"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </>
  );
}
