import {
  APIProvider,
  InfoWindow,
  Map,
  Marker,
  AdvancedMarker,
  useMarkerRef,
  Pin,
} from "@vis.gl/react-google-maps";
const API_KEY = "AIzaSyBwjpnIzj-isGE1JJEnadTkYsCrl1irTCI";

export default function GoogleMap() {
  const [markerRef, marker] = useMarkerRef();
  return (
    <APIProvider apiKey={API_KEY}>
      <Map
        style={{ width: "100vw", height: "100vh" }}
        defaultZoom={17}
        gestureHandling={"greedy"}
        disableDefaultUI={true}
        center={{ lat: 37.98696067489039, lng: -122.5880485746901 }}
        mapId={API_KEY}
      >
        <Marker
          ref={markerRef}
          position={{ lat: 37.98696067489039, lng: -122.5880485746901 }}
        ></Marker>
        <InfoWindow
          ref={marker}
          position={{ lat: 37.98696067489039, lng: -122.5880485746901 }}
        >
          <h2>Mac's at 19 Broadway</h2>
        </InfoWindow>
      </Map>
    </APIProvider>
  );
}
